<template>
  <div>
    <mu-container class="content">
      <mu-list>
        <mu-list-item avatar button :ripple="false" id="doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img :src="user.avatar" />
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              user.wechat_name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                >{{ user.mobile}}</span
              >
            </mu-list-item-sub-title>

          </mu-list-item-content>
        </mu-list-item>

      </mu-list>
        <mu-divider></mu-divider>
        <mu-list class="my">
          <mu-list-item button :ripple="false" :to="{name:'OrderList'}">
            <mu-list-item-action>
              <mu-icon value="assignment"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>预约记录</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button :ripple="false" :to="{name:'Patient'}">
            <mu-list-item-action>
              <mu-icon value="assignment_ind"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>就诊人管理</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button :ripple="false"  :to="{name:'Appointment'}">
            <mu-list-item-action>
              <mu-icon value="grade"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>快捷预约</mu-list-item-title>
          </mu-list-item>
          <!-- <mu-list-item button :ripple="false"  :to="{name:'Integral'}">
            <mu-list-item-action>
              <mu-icon value="grade"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>我的积分</mu-list-item-title>
          </mu-list-item> -->
          <mu-list-item button :ripple="false"  :to="{name:'Invite'}">
            <mu-list-item-action>
              <mu-icon value="near_me"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>推荐好友</mu-list-item-title>
          </mu-list-item>
        </mu-list>
        <mu-divider></mu-divider>
        <mu-list>
          <!-- <mu-list-item button :ripple="false">
            <mu-list-item-action>
              <mu-icon value="card_giftcard"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>会员权益</mu-list-item-title>
          </mu-list-item> -->
          <mu-list-item button :ripple="false"  :to="{name:'Profile'}">
            <mu-list-item-action>
              <mu-icon value="person"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>个人资料</mu-list-item-title>
          </mu-list-item>
        </mu-list>
        <mu-divider></mu-divider>
        <mu-list>
          <!-- <mu-list-item button :ripple="false">
            <mu-list-item-action>
              <mu-icon value="info_outline"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>关于可恩</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button :ripple="false">
            <mu-list-item-action>
              <mu-icon value="headset_mic"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>联系客服</mu-list-item-title>
          </mu-list-item> -->
          <mu-list-item button :ripple="false" @click="clearStorage()">
            <mu-list-item-action>
              <mu-icon value="delete"></mu-icon>
            </mu-list-item-action>
            <mu-list-item-title>清空缓存</mu-list-item-title>
          </mu-list-item>
        </mu-list>


    </mu-container>
    <Footer shift="my"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      user: {},
    };
  },
  created() {
    document.title = "个人中心 - 可恩口腔医院";
    this.get_user_info()

  },
  methods: {
    get_user_info(){
      this.$http.get('user_info')
      .then((res)=>{
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.user = res.data.data
      })
    },
    clearStorage(){
      window.localStorage.clear()
      this.$toast.success('清除成功！');
    }
  },
  watch: {},
};
</script>
<style scoped>
.my .mu-item{
  height:48px !important;
}


#doctor {
  margin: 30px 0px;
}
#doctor_name {
  font-weight: bold;
  font-size: 24px;
  height: 40px;
  line-height: 36px;
}
#doctor .mu-avatar {
  width: 100px !important;
  height: 100px !important;
}
#doctor .mu-item-action {
  min-width: 120px !important;
}
#doctor .mu-item-sub-title {
  line-height: 2;
  font-size: 18px;
  font-weight: 500;
}
</style>
