import axios from 'axios'
import wx from "weixin-js-sdk";
export default {
    wxShowMenu: function () {
        const url = location.href.split('#')[0];
        console.log(encodeURIComponent(url))
        if(decodeURIComponent(url).indexOf('?')!=-1){
            var shareUrl = url + '&from_user='+localStorage.user_id;
            
        }else{
            var shareUrl = url + '?from_user='+localStorage.user_id;
        }
        console.log(shareUrl)
        const imgUrl = 'https://yuyueapi.itkeen.com/uploads/images/keen.png';
        axios.post('https://yuyueapi.itkeen.com/wx_share', { url: url }).then(function (res) {
            
            var getMsg = res.data;
            console.log(res.data)
            // wx.config(res['appId']);
            // var wxconfigs = eval('('+data+')')
            wx.config({
                debug: false,  //生产环境需要关闭debug模式
                appId: getMsg.appId, //appId通过微信服务号后台查看
                timestamp: getMsg.timestamp, //生成签名的时间戳
                nonceStr: getMsg.nonceStr, //生成签名的随机字符串
                signature: getMsg.signature, //签名
                jsApiList: [ //需要调用的JS接口列表
                    // 'updateAppMessageShareData', //分享给好友
                    // 'updateTimelineShareData', //分享到朋友圈
                    'showMenuItems',
                    'hideMenuItems',
                    'onMenuShareAppMessage',
                    'onMenuShareTimeline',
                    'getLocation'
                    // 'onMenuShareTimeline',
                    // 'onMenuShareAppMessage'
                ]
            });
            wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: ["showMenuItems"],
                    success: function (res) {
                        wx.showMenuItems({
                            menuList: [
                                'menuItem:share:appMessage', //发送给朋友
                                'menuItem:share:timeline' //分享到朋友圈
                            ]
                        });
                    }
                });
                wx.updateAppMessageShareData({
                    title: "", // 分享标题
                    desc: "济南可恩口腔是一家专注于口腔服务行业的连锁品牌医院,是一所集牙齿种植、牙齿正畸、美容修复、儿童齿科、口腔治疗于一体的现代化专业口腔医院", //分享描述
                    link: shareUrl, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })

                //分享给朋友
                wx.updateTimelineShareData({
                    title: "", // 分享标题
                    link: shareUrl, // 分享链接
                    imgUrl: imgUrl // 分享图标
                });
                //获取“转发”按钮点击状态及自定义分享内容接口
                wx.onMenuShareAppMessage({
                    title: '', // 分享标题
                    desc: '济南可恩口腔是一家专注于口腔服务行业的连锁品牌医院,是一所集牙齿种植、牙齿正畸、美容修复、儿童齿科、口腔治疗于一体的现代化专业口腔医院', // 分享描述
                    link: shareUrl, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                    }
                });
                wx.onMenuShareTimeline({
                    title: '', // 分享标题
                    link: shareUrl,  // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    }
                });
                // wx.getLocation({
                //     type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                //     success: function (res) {
                //       this.alert(JSON.stringify(res))
                //       var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                //       var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                //       var speed = res.speed; // 速度，以米/每秒计
                //       var accuracy = res.accuracy; // 位置精度
                //       console.log(res)
                //       console.log('2222')
                //     },
                // });
            });
        })
    }
}