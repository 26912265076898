<template>
  <div>
    
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    
    
  },
  data(){
    return {
      code:'',
      local:window.location.href,
      form:{},

    }
  },
  created(){
    document.title = "企业微信登录";
    this.getCode();
  },
  methods:{

    getCode(){
      this.code=this.$route.query.code;
      //var code = getUrlParam('code');// 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      if (this.code == null || this.code == ''||this.code == "undefined") {
        //window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+APPID+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect';
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwc4c076f1a1d9ecb4&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_base&state=123#wechat_redirect';
        // window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxdcef53c6bc00eb35&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_login&state=123#wechat_redirect'
        return false;
      } else {
        this.login(); //把code传给后台获取用户信息
        return false;
      }
    },
    login(){
      // this.form.code = this.code
      var that = this;
      this.$http.post('wx_work_login',{'code':this.code})
      .then((res)=>{
        if(res.data.code != 200){
            this.$alert('没有此用户，请联系管理员')
            return false;
        }
        this.data = res.data.data.token;
        localStorage.token=res.data.data.token;
        localStorage.user_id=res.data.data.user_id;
        // this.$router.go(-2);
        if(res.data.code == 200){
          this.$router.push({name:'HisAddPatient'});
        }
        
        
      })
      .catch(function (error) {
        console.log(error)

        that.$router.replace({name:'HisAddPatient'});
        location. reload()
        return false;
      })

    },

  },
  mounted(){
      if(this.$route.query.from_user){
        localStorage.from_user = this.$route.query.from_user;
      }
  }

}
</script>


