<template>

  <div class="home">
  
    <mu-form :model="form" class="mu-demo-form" label-position="right" label-width="100">
    <mu-form-item prop="请输入用户手机号" label="手机号码">
      <mu-text-field v-model="mobile"></mu-text-field>
    </mu-form-item>
    <mu-form-item>
      <mu-button @click="getTestToken">提交</mu-button>
    </mu-form-item>
    
  </mu-form>
  
   <!-- <Footer></Footer> -->
  </div>

  
  

</template>

<script>
// @ is an alias to /src
import Footer from '../components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer

  },
  data(){
    return{
      mobile:'18953133433',
      users:{},
      user_show:false,
      name:'',
      form:{
        mobile:''
      },
    }
  },
  created(){
    // this.getCode();
    // console.log(this.$route.query)
    document.title = 'KEEN'

  },
  methods:{
    getUsers(){
      this.$http.post('get_users')
      .then((res)=>{
        this.users = res.data.data

      })
      .catch(function(e) {
        console.log(e); // "Uh-oh!"
      })
    },
    
    getTestToken(){
      this.$http.post('get_test_token',{'mobile':this.mobile})
      .then((res)=>{
        console.log(res)
        localStorage.token=res.data.data.token;
        localStorage.user_id=res.data.data.user_id;
        this.$alert('切换成功');
      })
      .catch(function(e) {
        console.log(e); // "Uh-oh!"
      })

    },
    user_onselect(item){
      console.log(item)
      this.user_show = false;
      this.name = item.name;
      this.userid = item.userid;
      console.log(this.userid)

    },
    

  }
}
</script>
<style scoped>
.home{
  margin:30px auto;
}
.payment{
  margin:0px 10px 100px 10px;
  padding-top:10px;
}
</style>
