<template>
  <div>
    <mu-container class="content">
      <!-- <mu-appbar color="indigo500" title="我的积分"> </mu-appbar> -->
      <mu-list>
        <mu-list-item avatar button :ripple="false" id="doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img :src="user.avatar" />
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              user.name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px">{{
                user.mobile
              }}</span>
            </mu-list-item-sub-title>
          </mu-list-item-content>
        </mu-list-item>
      </mu-list>
      <mu-divider></mu-divider>
      <mu-list>
        <mu-list-item avatar>
          <mu-list-item-action avatar>
            <mu-avatar>
              <mu-icon value="monetization_on"></mu-icon>
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title>我的积分：{{ integral }}</mu-list-item-title>
          </mu-list-item-content>
          <!-- <mu-list-item-action>
            <mu-icon value="chat_bubble">{{integral}}</mu-icon>
          </mu-list-item-action> -->
        </mu-list-item>
      </mu-list>
      <!-- <mu-divider></mu-divider> -->
      <mu-tabs inverse color="indigo500" indicator-color="indigo500">
        <mu-tab class="integral_title">积分变更记录</mu-tab>
      </mu-tabs>
      <mu-divider></mu-divider>
      <!-- <mu-list v-for="item in doctor.order_date" :key="item.day">
          <mu-list-item avatar button :ripple="false">
            <mu-list-item-title
              >{{ item.day }} {{ item.week }}</mu-list-item-title
            >
            <mu-list-item-action>
              <mu-button @click="openSelectTime(item)" color="indigo500"
                >预约</mu-button
              >
            </mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </mu-list> -->
    </mu-container>
    <Footer shift="my"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      user: {},
      integral: 0,
    };
  },
  created() {
    document.title = "我的积分 - 可恩口腔医院";
    this.get_user_info();
    
  },
  methods: {
    get_integral() {
      this.$http.get("integral").then((res) => {
        if(res.data.code == 201){
          this.$alert(res.data.msg)
          .then((res)=>{
            this.$router.push({'name':'Profile'})
            return false;
          })
        }
        this.integral = res.data.data;
      });
    },
    get_user_info() {
      this.$http.get("user_info").then((res) => {
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.user = res.data.data;
        this.get_integral();
      });
    },
  },
  watch: {},
};
</script>
<style scoped>
#doctor {
  margin: 30px 0px;
}
#doctor_name {
  font-weight: bold;
  font-size: 24px;
  height: 40px;
  line-height: 36px;
}
#doctor .mu-avatar {
  width: 100px !important;
  height: 100px !important;
}
#doctor .mu-item-action {
  min-width: 120px !important;
}
#doctor .mu-item-sub-title {
  line-height: 2;
  font-size: 18px;
  font-weight: 500;
}
.integral_title {
  /* color:#000  !important; */
  font-weight: bold !important;
}
</style>
