<template>
  <div class="appointment">
    <mu-container>
      <mu-row>
        <mu-col span="12">
          <mu-card style="width: 100%; margin: 0px auto;padding-bottom:20px;">
            <!-- <mu-card-media>
              <img src="/static/images/top.jpg" />
            </mu-card-media> -->
            <mu-card-title
              title="可恩口腔微信预约通道"
              sub-title="本表单信息仅用于本网站注册账号及预约使用！"
            ></mu-card-title>
            <mu-card-text>
              <mu-form
                ref="form"
                :model="orderForm"
                class="mu-demo-form"
                label-position="top"
                label-width="80"
              >
                <mu-form-item prop="name" label="姓名"  :rules="nameRules">
                  <mu-text-field v-model="orderForm.name"></mu-text-field>
                </mu-form-item>
                <mu-form-item prop="mobile" label="手机号码"  :rules="mobileRules">
                  <mu-text-field v-model="orderForm.mobile"></mu-text-field>
                </mu-form-item>
                
                <mu-form-item prop="jgbm" label="预约医院"  :rules="hospitalRules">
                  <mu-select v-model="orderForm.jgbm">
                    <mu-option
                      v-for="hospital in hospitals"
                      :key="hospital.jgbm"
                      :label="hospital.title"
                      :value="hospital.jgbm"
                    ></mu-option>
                  </mu-select>
                </mu-form-item>
                <mu-form-item prop="date" label="预约日期"  :rules="dateRules">
                  <mu-date-input
                    v-model="orderForm.date"
                    type="date"
                    value-format="YYYY-MM-DD"
                    
                    :min-date="min_date"
                    :max-date='max_date'
                  ></mu-date-input>
                </mu-form-item>
                <!-- <mu-form-item prop="select" label="预约日期">
                        <mu-select v-model="orderForm.order_date">
                            <mu-option v-for="item in order_date" :key="item.date" :label="item.date" :value="item.date"></mu-option>
                        </mu-select>
                        </mu-form-item> -->
                <!-- <mu-form-item prop="rybm" label="选择医生"  :rules="doctorRules">
                  <mu-select
                    v-model="orderForm.rybm"
                    @change="selectDoctor($event)"
                  >
                    <mu-option
                      v-for="item in doctors"
                      :key="item.rybm"
                      :label="item.name"
                      :value="item.rybm"
                    ></mu-option>
                  </mu-select>
                </mu-form-item> -->
                
                
                <mu-form-item prop="items" label="预约项目">
                  <mu-checkbox
                    v-model="orderForm.items"
                    key="种植牙"
                    value="种植牙"
                    label="种植牙"
                  ></mu-checkbox>
                  <mu-checkbox
                    v-model="orderForm.items"
                    key="牙齿美白"
                    value="牙齿美白"
                    label="牙齿美白"
                  ></mu-checkbox>
                  <mu-checkbox
                    v-model="orderForm.items"
                    key="牙齿修复（补牙"
                    value="牙齿修复（补牙）"
                    label="牙齿修复（补牙）"
                  ></mu-checkbox>
                  <mu-checkbox
                    v-model="orderForm.items"
                    key="儿牙"
                    value="儿牙"
                    label="儿牙"
                  ></mu-checkbox>
                  <mu-checkbox
                    v-model="orderForm.items"
                    value="拔牙（普通拔牙、拔智齿等）"
                    label="拔牙（普通拔牙、拔智齿等）"
                  ></mu-checkbox>

                  <mu-checkbox
                    v-model="orderForm.items"
                    value="洗牙"
                    label="洗牙"
                  ></mu-checkbox>
                </mu-form-item>
                <!-- <mu-form-item prop="switch" label="Switch">
                        <mu-switch v-model="form.switch"></mu-switch>
                        </mu-form-item>
                        <mu-form-item prop="slider" label="Slider">
                        <mu-slider v-model="form.slider"></mu-slider>
                        </mu-form-item> -->
                <mu-form-item prop="textarea" label="备注">
                  <mu-text-field multi-line :rows="1" :rows-max="6" v-model="orderForm.remark"></mu-text-field>
                </mu-form-item>
                <mu-button large round full-width @click="submit()" color="green500">提交预约</mu-button>
              </mu-form>
            </mu-card-text>
          </mu-card>
        </mu-col>
      </mu-row>
    </mu-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      hospitals: [],
      doctors: [],
      min_date:'',
      max_date:'',
      order_date: [],
      order_time: {},
      orderForm: {
        kfry_userid: this.$route.query.from_user,
        jgbm: "",
        rybm:"",
        doctor_skill:"",
        date: "",
        time:"",
        items: [],
      },
      nameRules: [
        { validate: (val) => !!val, message: "请填写姓名" },
        { validate: (val) => val.length >= 2 && val.length <= 12, message: "姓名长度必须大于2，小于10" },

      ],
      mobileRules: [
        { validate: (val) => !!val, message: "请填写手机号码" },
        { validate: (val) => val.length == 11 , message: '请输入正确的手机号码'},
      ],
      hospitalRules: [
        { validate: (val) => !!val, message: "请选择医院" },
        // { validate: (val) => val.length <= 18 , message: '请输入正确的身份证号码'}
      ],
      
      // dateRules: [
      //   { validate: (val) => !!val, message: "请选择预约日期" },
      //   // { validate: (val) => val.length <= 18 , message: '请输入正确的身份证号码'}
      // ],
      // doctorRules: [
      //   { validate: (val) => !!val, message: "请选择预约医生" },
      //   // { validate: (val) => val.length <= 18 , message: '请输入正确的身份证号码'}
      // ],
    };
  },
  created() {
    this.get_hospital();
    this.getDate();
    //   this.get_date();
    document.title = "可恩口腔通道";
  },
  methods: {
    getDate() {
      this.$http.get('min_max_date')
      .then((res)=>{
        if (res.data.code != 200) {
          return false;
        }
        this.min_date = new Date(res.data.data.min_date);
        this.max_date = new Date(res.data.data.max_date);
      })
    },
    
    get_hospital() {
      this.$http.get("hospital_ip").then((res) => {
        if (res.data.code == 204) {
          this.$router.push({name:'FastLogin',query:{from_user:localStorage.from_user}});
          return false;
        }
        this.hospitals = res.data.data;
      });
    },
    // get_doctors() {
    //   console.log(this.orderForm);
    //   this.$http.post("pb_doctor", this.orderForm).then((res) => {
    //     this.doctors = res.data.data;
    //   });
    // },
    // selectDoctor(item){
    //     console.log(item)
    //     let k = this.doctors.map(o=>o.rybm).indexOf(item);
    //     console.log(k)
    //     this.orderForm.doctor_skill = this.doctors[k].skills;
    //     this.get_order_time();

    // },
    // get_order_time() {
    //   this.$http.post("order_time", this.orderForm).then((res) => {
    //     this.order_time = res.data.data;
    //   });
    // },
    // selectTime(item) {
    //   this.orderForm.time = item.time;
    //   // console.log(this.orderForm);
    // },
    submit(){
      this.$refs.form.validate().then((result) => {
        if(!result){
          return false;
        }
        this.$http.post('fast_order',this.orderForm)
        .then((res)=>{
          if(res.data.code == 200){
            this.$alert(res.data.msg)
            .then((res)=>{
              this.$router.go(0)
            })
          }
        })
      });
    }

  },
  mounted() {
    if (this.$route.query.from_user) {
      localStorage.from_user = this.$route.query.from_user;
    }
  },
};
</script>
<style scoped>
.appointment {
  background: url(/static/images/yuyue_bg.png) no-repeat fixed top;
}
.time_select {
  background-color: #3f51b5 !important;
  color: white !important;
}
.notice{
    text-align:left;
}
</style>