<template>
  <div>
    <mu-container class="content">
      <!-- <mu-appbar color="indigo500" title="我的积分"> </mu-appbar> -->
      <mu-list>
        <mu-list-item avatar button :ripple="false" id="doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img :src="user.avatar" />
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              user.wechat_name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px">{{
                user.mobile
              }}</span>
            </mu-list-item-sub-title>
          </mu-list-item-content>
        </mu-list-item>
      </mu-list>
      <mu-divider></mu-divider>
      <!-- <mu-tabs inverse color="indigo500" indicator-color="indigo500">
        <mu-tab class="integral_title">邀请链接</mu-tab>
      </mu-tabs>
      <mu-divider></mu-divider>
      <mu-list>
        <mu-list-item avatar>
          <mu-list-item-action avatar>
            <mu-avatar>
              <mu-icon value="grade"></mu-icon>
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title>
              {{url}}
            </mu-list-item-title>
          </mu-list-item-content>
          <mu-list-item-action>
            <mu-button  v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError" color="indigo500"
                >复制</mu-button
              >
          </mu-list-item-action>
        </mu-list-item>
      </mu-list> -->
      <!-- <mu-tabs inverse color="indigo500" indicator-color="indigo500">
        <mu-tab class="integral_title">推广二维码</mu-tab>
      </mu-tabs>
      <mu-divider></mu-divider>
      <mu-list>
        <mu-list-item avatar>
          
          <mu-list-item-content>
            <mu-list-item-title>
              {{url}}
            </mu-list-item-title>
          </mu-list-item-content>
          <mu-list-item-action>
            <mu-button  v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError" color="indigo500"
                >复制</mu-button
              >
          </mu-list-item-action>

        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item avatar>
          
          <mu-list-item-content>
            <mu-list-item-title>
              推广二维码
            </mu-list-item-title>
          </mu-list-item-content>
          <mu-list-item-action>
            <mu-button @click="qrcode()" color="indigo500"
                >生成二维码</mu-button
              >
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <div id="qrcode" ref="qrcode" class="qrcode"></div>
      </mu-list> -->
      
      <mu-tabs inverse color="indigo500" indicator-color="indigo500">
        <mu-tab class="integral_title">推荐好友列表</mu-tab>
      </mu-tabs>
      <mu-divider></mu-divider>
      <mu-list v-for="item in invite_users" :key="item.id">
          <mu-list-item avatar button :ripple="false">
            <mu-list-item-title
              >{{ item.name }}</mu-list-item-title
            >
            <mu-list-item-action>
              {{ item.created_at }}
            </mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </mu-list>
    </mu-container>
    <Footer shift="my"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";
import QRCode from 'qrcodejs2'

export default {
  name: "Home",
  components: {
    Footer,QRCode
  },
  data() {
    return {
      user: {},
      integral: 0,
      invite_users:[],
      url:'',
    };
  },
  created() {
    document.title = "推荐好友 - 可恩口腔医院";
    this.get_user_info();
  },
  methods: {
    
    get_user_info() {
      this.$http.get("user_info").then((res) => {
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.user = res.data.data;
        this.get_invite_users()
      });
    },
    get_invite_users() {
      this.$http.get("invite_users").then((res) => {
        this.invite_users = res.data.data;
        this.url = 'https://yuyue.keenkq.com/?from_user='+this.user.id;
      });
    },
    qrcode(){
      document.getElementById('qrcode').innerHTML = ''
      let qrcode = new QRCode('qrcode', {
        width: 150, //图像宽度
        height: 150, //图像高度
        colorDark : "#000000", //前景色
        colorLight : "#ffffff", //背景色
        typeNumber:4, 
        correctLevel : QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      })
      qrcode.clear() //清除二维码 
      qrcode.makeCode(this.url) //生成另一个新的二维码
    },
    // 复制成功时的回调函数
    onCopy (e) {
    // this.$message.success("")
    this.$toast.success('内容已复制到剪切板！');
    },
    // 复制失败时的回调函数
    onError (e) {
    this.$toast.error('复制失败！');
    },
  },
  watch: {},
};
</script>
<style scoped>
#doctor {
  margin: 30px 0px;
}
#doctor_name {
  font-weight: bold;
  font-size: 24px;
  height: 40px;
  line-height: 36px;
}
#doctor .mu-avatar {
  width: 100px !important;
  height: 100px !important;
}
#doctor .mu-item-action {
  min-width: 120px !important;
}
#doctor .mu-item-sub-title {
  line-height: 2;
  font-size: 18px;
  font-weight: 500;
}
.integral_title {
  /* color:#000  !important; */
  font-weight: bold !important;
}
.qrcode{
  width:200px;
  height:200px;
  margin:10px auto;
}
</style>
