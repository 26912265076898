<template>
  <div>
    
  </div>

</template>

<script>
// @ is an alias to /src
import wx from "weixin-js-sdk";

export default {
  name: 'Home',
  components: {
    
    
  },
  data(){
    return {
      code:'',
      local:window.location.href,
      form:{},

    }
  },
  created(){
    document.title = "登录";
    this.getCode();
    
  },
  methods:{

    getCode(){
      this.code=this.$route.query.code;
      //var code = getUrlParam('code');// 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      console.log(this.code);
      if (this.code == null || this.code == ''||this.code == "undefined") {
        //window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+APPID+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect';
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5a2574cebce0b99c&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect';
        // window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxdcef53c6bc00eb35&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_login&state=123#wechat_redirect'
        return false;
      } else {
        this.login(); //把code传给后台获取用户信息
        return false;
      }
    },
    login(){
      // this.form.code = this.code
      var that = this;
      this.$http.post('login',{'code':this.code,'parent_userid':localStorage.from_user})
      .then((res)=>{
        if(res.data.code == 200){
          this.data = res.data.data.token;
          localStorage.token=res.data.data.token;
          localStorage.user_id=res.data.data.user_id;
          this.$router.push({name:'Home'});
          // this.getAddress()
          // this.$router.go(-2);//返回上一层
          return false;
        }
        
      })
      .catch(function (error) {
        that.$router.replace({name:'Login'});
        location. reload()
        return false;
      })

    },
    getAddress(){
      console.log('getaddress')
      // var map = new qq.maps.Geolocation("4SHBZ-WHPR2-NNTUJ-CKQE2-FGUH2-HZB2G", "可恩口腔h5");
      // map.getLocation(this.showPosition,this.showErr)
      var that = this
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          // this.alert(JSON.stringify(res))
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          localStorage.latitude = res.latitude
          localStorage.longitude = res.longitude
        },
        fail:function(res){
        },
        complete:function(res){
          that.$router.push({name:'Home'});
        },
      });
      
    }

  },
  mounted(){
    if(this.$route.query.from_user){
      localStorage.from_user = this.$route.query.from_user;
    }
  }

}
</script>


