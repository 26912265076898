<template>
  <div>
    <mu-container class="content">
      <div v-wechat-title="title"></div>
       <!-- <mu-appbar color="indigo500" title="预约详情">
      </mu-appbar> -->
      <mu-list>
        <mu-list-item avatar :ripple="false" id="doctor" v-if="order.doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img :src="order.doctor.avatar"/>
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              order.doctor.name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span class="order_content"
                >{{ order.hospital.title }}</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item button v-if="order.hospital">
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊医院：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span class="order_content">{{order.hospital.title}}</span></mu-list-item-action>
        </mu-list-item>
        <!-- <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊科室：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px">{{doctorForm.office}}</span></mu-list-item-action>
        </mu-list-item> -->
        <mu-list-item button v-if="order.hospital">
          <mu-list-item-content >
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊地点：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span class="order_content">{{order.hospital.address}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button v-if="order.hospital">
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >联系电话：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span class="order_content"><a :href="'tel:'+order.hospital.telephone">{{order.hospital.telephone}}</a></span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊日期：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span class="order_content">{{order.order_date}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊时间：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span class="order_content">{{order.order_time}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
              
                >就诊人：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action >
            <span class="order_content" v-if="order.patient" >{{order.patient.name}}/{{order.patient.mobile}}</span>
            

          </mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >备注：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span  class="order_content">{{order.remark}}</span></mu-list-item-action>
        </mu-list-item>
        <!-- <mu-form>
        <mu-form-item prop="textarea" label="Textarea">
          <mu-text-field multi-line :rows="3" :rows-max="6" v-model="doctorForm.remark"></mu-text-field>
        </mu-form-item>
        </mu-form> -->
        <mu-divider></mu-divider>
        

      </mu-list>
      <div class="information">
        <i class="mdui-icon material-icons">&#xe000;</i>
        <span>预约即表示同意</span><span @click="noticeDialog=true" style="color:#3f51b5;font-weight:bold;">《预约须知》</span>
      </div>
      <mu-card style="width: 90%; max-width: 1000px; margin: 20px auto 100px auto;">
        <mu-card-text class="notice">
          <span style="font-weight:bold">温馨提示</span><br/>
          <!-- ★请在预约时间之前携带身份证完成就诊卡实名认证。<br/>
          ★如需取消预约，请在就诊日前一日22点之前操作。<br/> -->
          ★预约时间点成功提交后，仅代表预约详细信息提交成功；<br/>
          ★具体预约时间点，我们会以最终工作人员和您确认的预约事项来调整；<br/>
          ★如您不清楚就诊需要的时间长短，可电话咨询；<br/>
          ★需加号预约，可致电门店，确认是否可以加号；
        </mu-card-text>
      </mu-card>

      
      
    </mu-container>
    <!-- <Footer  shift="my"/> -->

    <mu-dialog width="360" :open.sync="noticeDialog">
    <span v-html="order.hospital.notice" class="notice_content"></span>
    <mu-button slot="actions" flat color="primary" @click="noticeDialog=false">关闭</mu-button>
  </mu-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";
import SelectPatient from "../../components/order/SelectPatient.vue"

export default {
  name: "Home",
  components: {
    Footer,
    SelectPatient,
  },
  data() {
    return {
      title:'预约详情',
      id:this.$route.params.id,
      order:{},
      notice:'',
      noticeDialog:false,
    };
  },
  created() {
    
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail(){
      this.$http.get('/order/'+this.id)
      .then((res)=>{
        if(res.data.code != 200){
          this.$alert(res.data.msg);
          return false;
        }
        this.order = res.data.data
      })
    }
  },
  watch: {},
  mounted(){
    // console.log(this.doctor.name)
    // document.title = this.doctor.name+' - '+this.hospital.title;

  }
};
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
.content {
  margin-bottom: 85px;
}
.mu-card {
  margin: 12px auto;
}
/* .mu-item{
  margin:10px 10px;
  border:1px solid #ccc;
  border-radius: 10px;
  padding:10px;
  
} */
/* .mu-item{
  margin:2px auto;
} */
.mu-select {
  margin: 10px;
}
#doctor {
  margin:30px auto;
}
.mu-list li {
  margin: 0px 0px;
}
.mu-avatar {
  width: 100px !important;
  height: 100px !important;
}
.mu-item-action {
  min-width: 120px !important;
}
.mu-sub-header {
  text-align: left;
  font-weight: bold;
  font-size: 36px;
}
#doctor_name {
  font-weight: bold;
  font-size: 28px;
  height: 50px;
  line-height: 40px;
}
.mu-item-sub-title {
  line-height: 2;
  font-size: 14px;
  font-weight: 500;
}
.mu-item-sub-title .material-icons {
  font-size: 24px;
  height:40px;
}
.mu-select {
  width: 90% !important;
}
.order_date {
  font-weight: bold;
  font-size: 18px;
  line-height: 3em;
}
.date_active {
  color: white;
  background-color: #3f51b5;
  border-radius: 50%;
  max-width: 3em;
  /* padding:3px; */
  /* width:35px; */
}
.mu-tab-wrapper {
  font-weight: bold !important;
}
.profile p {
  margin: 20px 0;
  text-align: left;
  text-indent:2em;
}
#time {
  margin: 8px 5px;
}
.time_select {
  background-color: #3f51b5 !important;
  color: white !important;
}
.submit {
  margin: 30px auto;
  text-align: center;
}
/* .submit button{
  width:300px;
} */
.select_top {
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin: 10px auto;
  font-size: 20px;
}
.information{
  display:block;
  
}
.information span{
  color:#000;
  margin-top:10px;
}
.information .material-icons{
  
  font-size:24px;
  margin-top:10px;
}
.notice{
  background-color: #eee;
  line-height:28px;
  text-align: left;
}
.mu-form-item .mu-input{
  height:48px;
  padding-top:15px;
}
/* .mu-dialog-fullscreen .mu-dialog-body {
    padding: 0;
    max-height: 1200px !important;
} */
.notice_content{
  font-size:12px;
}
.order_content{
  font-size:14px;
  font-weight:bold;
}
.order_content a{
  color:#3f51b5;
}
</style>
<style>
.doctor_title {
  /* color:#000  !important; */
  font-weight: bold !important;
}
</style>