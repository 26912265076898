<template>
  <div>
    <mu-container class="content">
      
        <mu-col span="12" sm="10">
          <mu-select v-model="jgbm">
            <mu-option
              v-for="option in hospitals"
              :key="option.jgbm"
              :label="option.title"
              :value="option.jgbm"
            ></mu-option>
          </mu-select>
        </mu-col>
        <!-- <mu-sub-header>预约到店</mu-sub-header> -->
        <mu-list v-for="item in offices" :key="item.ksbm">
          <mu-list-item avatar button :ripple="false" :to="{name:'Office',query:{jgbm:jgbm,ksbm:item.ksbm}}">
            <mu-list-item-content>
              <mu-list-item-title>{{ item.title }}</mu-list-item-title>
              <!-- <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe8b4;</i>地址：{{item.address}}</mu-list-item-sub-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe0cd;</i>电话：{{item.telephone}}</mu-list-item-sub-title> -->
            </mu-list-item-content>
            <mu-list-item-action>
              <mu-button icon>
                <mu-icon value="play_circle_outline"></mu-icon>
              </mu-button>
            </mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </mu-list>
      

      
    </mu-container>
    <Footer  shift="home"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      hospitals: [],
      offices: [],
      jgbm: this.$route.query.jgbm,
      
    };
  },
  created() {
    document.title = "选择科室";
    this.getOffices();
    this.getHospitals();
    console.log(this.jgbm);
  },
  methods: {
    getHospitals() {
      this.$http.get("hospital").then((res) => {
        console.log(res);
        this.hospitals = res.data.data;
      });
    },
    getOffices() {
      this.$http.get("office").then((res) => {
        console.log(res);
        this.offices = res.data.data;
      });
    },
  },
};
</script>
<style scope>
img {
  width: 100%;
  height: auto;
}
.content {
  margin-bottom: 85px;
}
/* .mu-item{
  margin:10px 10px;
  border:1px solid #ccc;
  border-radius: 10px;
  padding:10px;
  
} */
/* .mu-item{
  margin:2px auto;
} */
.mu-select {
  margin: 10px;
}
.mu-sub-header {
  text-align: left;
  font-weight: bold;
  font-size: 36px;
}
.mu-item-sub-title {
  line-height: 2;
}
.mu-item-sub-title .material-icons {
  font-size: 18px;
}
</style>
