import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Appointment from '../views/appointment/Appointment.vue'
import Hospital from '../views/order/Hospital.vue'
import Office from '../views/order/Office.vue'
import Doctor from '../views/order/Doctor.vue'
import My from '../views/user/My.vue'
import Login from '../views/user/Login.vue'
import FastLogin from '../views/user/FastLogin.vue'
import WxWorkLogin from '../views/user/WxWorkLogin.vue'

import Patient from '../views/user/Patient.vue'
import Test from '../views/Test.vue'
import OrderList from '../views/user/OrderList.vue'
import OrderDetail from '../views/user/OrderDetail.vue'
import Profile from '../views/user/Profile.vue'
import Integral from '../views/user/Integral.vue'
import Invite from '../views/user/Invite.vue'
import Location from '../views/Location.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'Home',component: Home,meta: {title: '可恩口腔医院在线预约'},},
  {path: '/hospital',name: 'Hospital',component: Hospital},
  {path: '/office',name: 'Office',component: Office},
  {path: '/doctor',name: 'Doctor',component: Doctor},
  {path: '/fast_login',name: 'FastLogin',component: FastLogin}, 
  {path: '/yuyue',name: 'Appointment',component: Appointment,meta: {title: '可恩口腔医院在线预约'},},  
   

  {path: '/location',name: 'Location',component: Location},


  {path: '/test',name: 'Test',component: Test},  
  //用户中心
  {path: '/my',name: 'My',component: My,meta: {title: '个人中心'},},  
  {path: '/login',name: 'Login',component: Login},  
  {path: '/wx_work_login',name: 'WxWorkLogin',component: WxWorkLogin},  
  {path: '/my/patient',name: 'Patient',component: Patient}, 
  {path: '/my/orders',name: 'OrderList',component: OrderList},  
  
  {path: '/my/order/:id',name: 'OrderDetail',component: OrderDetail},  
  {path: '/my/profile',name: 'Profile',component: Profile},  
  {path: '/my/integral',name: 'Integral',component: Integral},  
  {path: '/my/invite',name: 'Invite',component: Invite}, 
  //企业微信
  {path: '/daiyuyue',name: 'Daiyuyue',component: () => import( '../views/wx_work/Daiyuyue.vue')},
  //his建档
  {path: '/his_add_patient',name: 'HisAddPatient',component: () => import( '../views/wx_work/hisAddPatient.vue')},  
  {path: '/my/wxwork_orders',name: 'WxWorkOrderList',component: () => import( '../views/wx_work/WxWorkOrderList.vue')},  
  {path: '/wx_order/:id',name: 'WxOrderDetail',component: () => import( '../views/wx_work/WxOrderDetail.vue')},   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
