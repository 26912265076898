<template>
  <div>
    <mu-container class="content">
      <mu-appbar color="indigo500" title="编辑个人资料"> </mu-appbar>
      <div style="padding: 20px">
        <!-- <el-form ref="form" :model="form" label-width="90px">
          <el-form-item label="姓名">
            <el-input v-model="userForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="userForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input v-model="userForm.code"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码">
            <el-input v-model="userForm.id_card"></el-input>
          </el-form-item>

          
          <el-form-item>
            <mu-button color="indigo500" @click="submit" large>提交</mu-button>
          </el-form-item>
        </el-form> -->
        <mu-form
          ref="form"
          :model="userForm"
          label-position="left"
          label-width="80"
          class="mu-demo-form"
        >
          <mu-form-item
            label="姓名"
            help-text="请输入真实姓名"
            prop="name"
            
            :rules="nameRules"
          >
            <mu-text-field v-model="userForm.name" prop="name" :max-length="10"></mu-text-field>
          </mu-form-item>
          <mu-form-item label="手机号码"    prop="mobile"  :rules="mobileRules">
            <mu-text-field
              v-model="userForm.mobile"
              prop="mobile"
              :max-length="11"
              
              @change="changeMobile()"
              
            >
            </mu-text-field>
            
          </mu-form-item>
          <mu-form-item
            label="验证码"
            prop="code"
            :rules="codeRules"
          >
          
            <mu-text-field v-model="userForm.code" prop="code" :max-length="6" >
              <mu-button color="indigo500" :disabled="send_code_show==false" small @click="send_code()">发送验证码</mu-button>
            </mu-text-field>
            
          </mu-form-item>
          <mu-form-item label="身份证号码" prop="id_card" :rules="idCardRules" >
            <mu-text-field
              v-model="userForm.id_card"
              prop="id_card"
              :max-length="18"
            ></mu-text-field>
          </mu-form-item>
          <mu-form-item>
            <mu-button color="indigo500" @click="submit" large>提交</mu-button>
          </mu-form-item>
        </mu-form>
      </div>
    </mu-container>
    <Footer shift="my" />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      userForm: {
        name: "",
        mobile: "",
        
        // is_default:true,
      },
      send_code_show:false,

      nameRules: [
        { validate: (val) => !!val, message: "请填写姓名" },
        {
          validate: (val) => val.length >= 2 && val.length <= 12,
          message: "姓名长度必须大于2，小于10",
        },
      ],
      mobileRules: [
        { validate: (val) => !!val, message: "请填写手机号码" },
        {
          validate: (val) => val.length == 11,
          message: "请输入正确的手机号码",
        },
      ],
      codeRules: [
        { validate: (val) => !!val, message: "请填写验证码" },
        {
          validate: (val) => val.length == 6,
          message: "请输入正确的验证码",
        },
      ],
      idCardRules: [
        // { validate: (val) => !!val, message: "必须填写身份证号码" },
        // { validate: (val) => val.length <= 18 , message: '请输入正确的身份证号码'}
      ],
    };
  },
  created() {
    document.title = "编辑个人资料 - 可恩口腔医院";
    this.get_user_info();
  },
  methods: {
    get_user_info() {
      this.$http.get("user_info").then((res) => {
        if (res.data.code == 204) {
          this.$router.push({ name: "Login" });
          return false;
        }
        this.userForm = res.data.data;
      });
    },
    changeMobile(){
      console.log(this.userForm.mobile)
      if(this.userForm.mobile.length == 11){
        this.send_code_show = true;
      }else{
        this.send_code_show = false;
      }
    },
    send_code(){
      if(this.userForm.mobile.length !=11 ){
        this.$alert('请输入正确的手机号码！');
      }
      this.$http.post('send_code',this.userForm)
      .then((res)=>{
        if(res.data.code == 200){
          this.$alert(res.data.msg);
        }
      })

    },
    submit() {
      this.$refs.form.validate().then((result) => {
        console.log("form valid: ", result);
        if (!result) {
          return false;
        }
        this.$http.post("user/edit", this.userForm).then((res) => {
          if (res.data.code != 200) {
            this.$alert(res.data.msg);
            return false;
          }
          this.$alert(res.data.msg);
          this.get_user_info();
          // .then((res)=>{
          //   this.$router.push({'name':'My'});
          // })
        });
      });
    },
  },
  watch: {
    'userForm.mobile':function(newValue,oldValue){
      if(newValue.length == 11){
        this.send_code_show = true;
      }else{
        this.send_code_show = false;
      }
    }
  },
};
</script>
<style scoped>
.patient {
  padding: 30px 0px;
}
/* .mu-item {
  height: 84px !important;
} */
.patient .mu-item-title {
  line-height: 36px;
  height: 40px;
}
.patient .material-icons {
  line-height: 36px;
  font-size: 24px;
  color: #f44336 !important;
}
.patient .mu-item-sub-title {
  font-size: 14px;
  line-height: 28px;
}
</style>
