<!--
 * @Author: 王时霞
 * @Date: 2021-09-22 14:02:23
 * @LastEditors: 王时霞
 * @LastEditTime: 2023-02-07 11:46:59
 * @Description: file content
 * @FilePath: \yuyue.keenkq.com\src\components\Footer.vue
-->
<template>
  <div class='footer_bottom'>
    <mu-container :value="shift"  shift class="index_bottom">
      <mu-bottom-nav color="indigo500">
          <mu-bottom-nav-item  color="indigo500" value="home" title="我要预约" icon="assignment" active-class='sle' :to="{name:'Home'}"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="my" title="个人中心" icon="account_box"  active-class='sle' :to="{name:'My'}"></mu-bottom-nav-item>
      </mu-bottom-nav>
    </mu-container>

  </div>
</template>
<script>


export default {
  name: "Home",
  props: {
    shift:String
  },
  components: {
    
  },
  data() {
    return {
      
    };
  },
  created() {
    console.log(this.shift)
    
  },
  methods: {
    
  },
 
};
</script>
<style>
.footer_bottom{
  background-color: white;
  position:fixed;
  bottom:0px;
  left:0px;
  right:0px;
  margin:0px auto;
  z-index:99;
  

}
.index_bottom{
  margin:10px auto 0px auto;
  /* padding-top:20px; */
}
.index_bottom .router-link-exact-active {
    padding-top: 6px;
    padding-bottom: 5px;
    color: #3f51b5;
}
</style>
