<template>
  <div>
    <mu-container class="content">
      <mu-row>
        <!-- <mu-col span="0">
          <img src="/static/images/yuyue.jpg">
        </mu-col> -->
      </mu-row>
      <div class='tabs'>
        <mu-tabs inverse color="indigo500" indicator-color="indigo500" >
            <mu-tab  class="index_title" @click="getHospitalsAddress()">预约到店</mu-tab>
            <mu-tab  class="index_title" @click="getHospitals()">所有医院</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
      </div>
        
        <mu-list v-for="item in hospitals" :key='item.jgbm' class="hospital">
          
          <mu-list-item avatar button :ripple="false" :to="{name:'Office',query:{jgbm:item.jgbm}}">
            <mu-list-item-content>
              <mu-list-item-title>{{item.title}}<span style="font-weight:400;" v-if="item.distance">({{item.distance}}km)</span></mu-list-item-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons" color="indigo500">&#xe8b4;</i>{{item.address}}</mu-list-item-sub-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons" color="indigo500">&#xe0cd;</i>{{item.telephone}}</mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action>
              <mu-button icon>
                <mu-icon value="chevron_right"></mu-icon>
              </mu-button>
            </mu-list-item-action>
          </mu-list-item>
        <mu-divider></mu-divider>  
        </mu-list>
        

      

      <!-- <mu-row>
        
      </mu-row> -->
      
    </mu-container>
    <Footer shift="home"/>
  </div>

</template>

<script>
// @ is an alias to /src
import Footer from '../components/Footer.vue'
import wx from "weixin-js-sdk";

export default {
  name: 'Home',
  components: {
    Footer,
    
  },
  data(){
    return {
      hospitals:[],
      code:'',
      local:window.location.href,
      form:{},
      position:'',
      options:{timeout: 10, failTipFlag: true},
      coordinate:{
        latitude:'',
        longitude:'',
      }

    }
  },
  created(){
    document.title = "可恩口腔医院在线预约";
    // this.getHospitals();
    // this.getHospitalsIp();
    // this.getHospitalsIp();
    this.getAddress();
    // this.getHospitalsAddress()

  },
  methods:{
    getAddress(){
      console.log('getaddress')
      var that = this
      // var map = new qq.maps.Geolocation("4SHBZ-WHPR2-NNTUJ-CKQE2-FGUH2-HZB2G", "可恩口腔h5");
      // map.getLocation(this.showPosition,this.showErr)
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          // this.alert(JSON.stringify(res))
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          that.coordinate.latitude = res.latitude
          that.coordinate.longitude = res.longitude
          that.getHospitalsAddress()
          
          // console.log(res)
          console.log('2222')
        },
        cancel: function (res) {

          that.getHospitalsIp();

        },
        error:function(){
          console.log('001')
          that.getHospitalsAddress()
        },
        fail:function(res){
          console.log(res)
          console.log('111')
          that.getHospitalsAddress()
        },
        complete:function(res){
          that.getHospitalsAddress()
          console.log('23333')
        },
      });
      

    },
    showPosition(position){
      // console.log(position.city)
      console.log(position)
      this.position = position
      this.getHospitalsMap(position)

    },
    showErr(){
      console.log('err')
      this.getHospitalsIp()
    },
    getHospitalsAddress(){
      if(this.coordinate.longitude == ''){
        // this.getAddress()
        this.getHospitalsIp();
        return false;
      }
      this.$http.post('hospital_coordinate',this.coordinate)
      .then((res)=>{
        // this.$alert(res.data)
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.hospitals = res.data.data;
      })
    },
    getHospitalsMap(position){
      this.$http.post('hospital_map',position)
      
      .then((res)=>{
        // this.$alert(res.data)
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.hospitals = res.data.data;
      })
    },
    getHospitalsIp(){
      // if(localStorage.latitude){
      //   this.getHospitalsAddress()
      //   return false;
      // }
      this.$http.get('hospital_ip')
      .then((res)=>{
        if(res.data.code == 204){
          this.$router.push({'name':'Login',query:{from_user:this.$route.query.from_user}})
          return false;
        }
        this.hospitals = res.data.data;
      })
    },
    getHospitals(){
      this.$http.get('hospital')
      .then((res)=>{
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.hospitals = res.data.data;
      })
    },
    getCode(){
      this.code=this.$route.query.code;
      //var code = getUrlParam('code');// 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      console.log(this.code);
      if (this.code == null || this.code == ''||this.code == "undefined") {
        console.log(3)
        //window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+APPID+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect';
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx07fcf0e3ad880734&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect';
        // window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxdcef53c6bc00eb35&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_login&state=123#wechat_redirect'
        return false;
      } else {
        this.login(); //把code传给后台获取用户信息
        return false;
      }
    },
    login(){
      // this.form.code = this.code
      this.$http.post('login',{'code':this.code})
      .then((res)=>{
        this.data = res.data.data.token;
        localStorage.token=res.data.data.token;
        // this.$router.go(-2);//返回上一层
      })

    },

  },
  mounted(){
    // this.WXConfig.wxShowMenu();
    if(this.$route.query.from_user){
      localStorage.from_user = this.$route.query.from_user;
    }
  }

}
</script>
<style scoped>

/* .mu-item{
  margin:10px 10px;
  border:1px solid #ccc;
  border-radius: 10px;
  padding:10px;
  
} */
.tabs{
  margin-bottom:20px;
}
.mu-item{
  margin:2px auto;
}
.mu-list li {
  margin: 15px 0px;
}
.mu-item-title {
  font-weight: bold;
}
.mu-sub-header{
  text-align:left;
  font-weight:bold;
  font-size:36px;
}
.hospital .material-icons {
  line-height: 28px;
  font-size:28px;
  color:#3f51b5 !important;
}
.mu-item-sub-title{
  line-height:28px !important;
  margin:5px;
}
.mu-item-sub-title .material-icons{
  font-size:18px !important;
}
.index_title {
  /* color:#000  !important; */
  font-weight: bold !important;
}
</style>
<style>
img{
  width:100%;
  height:auto;
}
.content{
  margin-bottom:85px;
}
.mu-item-title {
  font-weight: bold;
}
.material-icons{vertical-align:bottom}
/* .mu-item{
  height:auto !important;
} */

</style>
