<template>
  <div>
    <div v-wechat-title="title"></div>
    <mu-container class="content">
      <mu-row>
        <mu-col sm="12">
          <mu-tabs
            :value.sync="tab_active"
            color="indigo500"
            indicator-color="indigo50"
          >
            <mu-tab>全部医生</mu-tab>
            <mu-tab>值班医生</mu-tab>
          </mu-tabs>
        </mu-col>
      </mu-row>
      <!-- <mu-row>
          <mu-col  sm="12">
          <mu-select v-model="doctorForm.ksbm" @change="getDoctors()"  full-width>
            <mu-option
              v-for="option in offices"
              :key="option.ksbm"
              :label="option.title"
              :value="option.ksbm"
              
            ></mu-option>
          </mu-select>
        </mu-col>
        </mu-row> -->
      <!-- <mu-sub-header>预约到店</mu-sub-header> -->
      <mu-row>
        <div style="width: 100%; background: #fff; padding: 8px">
          <mu-flex class="flex-wrapper" align-items="center">
            <mu-flex
              class="order_week"
              justify-content="center"
              fill
              v-for="item in order_dates"
              :key="item.day"
              >{{ item.week }}</mu-flex
            >
          </mu-flex>
          <mu-flex class="flex-wrapper" align-items="center">
            <mu-flex
              :class="{ date_active: item.date == doctorForm.date }"
              class="order_date"
              justify-content="center"
              fill
              v-for="item in order_dates"
              :key="item.day"
              @click="changeDay(item)"
              ><span>{{ item.day }}</span></mu-flex
            >
          </mu-flex>
        </div>
      </mu-row>
      <div v-if="tab_active === 0">
        <mu-card v-for="item in doctors" :key="item.rybm">
          <mu-list>
            <mu-list-item
              avatar
              button
              :ripple="false"
              :to="{ name: 'Doctor', query: { jgbm: jgbm, rybm: item.rybm } }"
            >
              <mu-list-item-action>
                <mu-avatar>
                  <img v-if="item.avatar" :src="item.avatar" />
                  <img v-else src="/static/images/keen.png" />
                </mu-avatar>
              </mu-list-item-action>
              <mu-list-item-content>
                <mu-list-item-title>{{ item.name }}</mu-list-item-title>
                <mu-list-item-sub-title v-if="item.office">
                  <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                    >{{ item.office.title }}</span
                  >
                </mu-list-item-sub-title>
                <!-- <mu-list-item-title>{{ item.name }}</mu-list-item-title> -->
                <!-- <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe8b4;</i>地址：{{item.address}}</mu-list-item-sub-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe0cd;</i>电话：{{item.telephone}}</mu-list-item-sub-title> -->
              </mu-list-item-content>
              <mu-list-item-action>
                <!-- <mu-button icon>
                <mu-icon value="play_circle_outline"></mu-icon>
              </mu-button> -->
                <mu-button color="indigo500">预约</mu-button>
              </mu-list-item-action>
            </mu-list-item>
            <div class="doctor_content" v-if="item.skills">擅长：{{item.skills}}</div>
            <!-- <mu-divider></mu-divider> -->
          </mu-list>
        </mu-card>
      </div>

      <!-- 值班医生 -->
      <div v-if="tab_active === 1">
        <mu-list v-for="item in pb_doctors" :key="item.rybm">
          <mu-list-item
            avatar
            button
            :ripple="false"
            :to="{ name: 'Doctor', query: { jgbm: jgbm, rybm: item.rybm } }"
          >
            <mu-list-item-action>
              <mu-avatar>
                <img v-if="item.avatar" :src="item.avatar" />
              <img v-else src="/static/images/keen.png" />
              </mu-avatar>
            </mu-list-item-action>
            <mu-list-item-content>
              <mu-list-item-title>{{ item.name }}</mu-list-item-title>
              <mu-list-item-sub-title v-if="item.office">
                <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                  >{{ item.office.title }}</span
                >
                
              </mu-list-item-sub-title>
              <!-- <mu-list-item-title>{{ item.name }}</mu-list-item-title> -->
              <!-- <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe8b4;</i>地址：{{item.address}}</mu-list-item-sub-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe0cd;</i>电话：{{item.telephone}}</mu-list-item-sub-title> -->
            </mu-list-item-content>
            <mu-list-item-action>
              <mu-button color="indigo500">预约</mu-button>
            </mu-list-item-action>
          </mu-list-item>
          <div class="doctor_content" v-if="item.skills">擅长：{{item.skills}}</div>
          <mu-divider></mu-divider>
        </mu-list>
      </div>

      <!-- <mu-row>
        
      </mu-row> -->
    </mu-container>
    <Footer shift="home"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      title:'',
      tab_active: 1,
      hospitals: [],
      offices: [],
      doctors: [],
      pb_doctors: [],
      jgbm: this.$route.query.jgbm,
      ksbm: this.$route.query.ksbm,
      doctorForm: {
        jgbm: this.$route.query.jgbm,
        ksbm: this.$route.query.ksbm,
        date: "",
      },
      order_dates: [],
    };
  },
  created() {
    // document.title = "选择医生";
    this.getOffices();
    // this.getHospitals();
    this.getDoctors();
    this.getHospital_detail();

    this.getOrderDate();
    // console.log(this.jgbm);
  },
  methods: {
    getHospital_detail(){
      this.$http.get('hospital/'+this.jgbm)
      .then((res)=>{
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.title  = res.data.data.title;
        // this.hospital = res.data.data
      })
    },
    getHospitals() {
      this.$http.get("hospital").then((res) => {
        console.log(res);
        this.hospitals = res.data.data;
      });
    },
    getOffices() {
      this.$http.get("office").then((res) => {
        console.log(res);
        this.offices = res.data.data;
      });
    },
    getDoctors() {
      this.$http.post("doctor", this.doctorForm).then((res) => {
        this.doctors = res.data.data;
      });
    },
    getPbDoctors() {
      this.$http.post("pb_doctor", this.doctorForm).then((res) => {
        console.log(res);
        this.pb_doctors = res.data.data;
      });
    },
    getOrderDate() {
      this.$http.get("order_date").then((res) => {
        this.order_dates = res.data.data.order_date;
        this.doctorForm.date = res.data.data.day;
        this.getPbDoctors();
      });
    },
    changeDay(item) {
      this.doctorForm.date = item.date;
      this.tab_active = 1;
      this.getPbDoctors();
    },
  },
  watch: {},
  // mounted() {
  //   this.WXConfig.wxShowMenu();
  // },
};
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
.content {
  margin-bottom: 85px;
}
.mu-card {
  margin: 12px auto;
}
/* .mu-item{
  margin:10px 10px;
  border:1px solid #ccc;
  border-radius: 10px;
  padding:10px;
  
} */
/* .mu-item{
  margin:2px auto;
} */
.mu-select {
  margin: 10px;
}
.mu-list li {
  margin: 20px 0px;
}
.mu-avatar {
  width: 60px !important;
  height: 60px !important;
}
.mu-item-action {
  min-width: 76px !important;
}
.mu-sub-header {
  text-align: left;
  font-weight: bold;
  font-size: 36px;
}
.mu-item-title {
  font-weight: bold;
}
.mu-item-sub-title {
  line-height: 2;
}
.mu-item-sub-title .material-icons {
  font-size: 18px;
}
.mu-select {
  width: 90% !important;
}
.order_date {
  font-weight: bold;
  font-size: 1.1em;
}

.order_date span{
  padding: 0.5em;
  min-width:2em;
}
.date_active span {

  color: white;
  background-color: #3f51b5;
  border-radius: 50%;
}
.doctor_content{
  text-align:left;
  margin: 10px;
  /* text-indent:2em; */
}
</style>
