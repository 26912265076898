<template>
  <div>
    <mu-container class="content">
      <div v-wechat-title="title"></div>
      <!-- 值班医生 -->
      <mu-list>
        <mu-list-item avatar button :ripple="false" id="doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img v-if="doctor.avatar" :src="doctor.avatar" />
              <img v-else src="/static/images/keen.png" />
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              doctor.name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                v-if="doctor.office">{{ doctor.office.title }} - {{ hospital.title }}</span
              >
            </mu-list-item-sub-title>
            <!-- <mu-list-item-title>{{ item.name }}</mu-list-item-title> -->
            <!-- <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe8b4;</i>地址：{{item.address}}</mu-list-item-sub-title>
              <mu-list-item-sub-title><i class="mdui-icon material-icons">&#xe0cd;</i>电话：{{item.telephone}}</mu-list-item-sub-title> -->
          </mu-list-item-content>
        </mu-list-item>
        <!-- <mu-divider></mu-divider> -->
        <!-- <div class="profile">
            <p>“……是的，我承认从很早以前我都害怕死亡，在医院里被人研究的时候，以前做出布局的时候，其实我很怕死的，因为除了死以外，我没有值得珍惜的东西，很奇怪是吧？恰好是这样我真的很怕死，因为我想要有值得珍惜的东西之后，死亡之后才会不寂寞，我才能够安然的面对死亡……”</p>
        </div> 
        <mu-divider></mu-divider>-->
      </mu-list>
      <mu-container>
        <div v-if="doctor.profile">
        <mu-tabs inverse color="indigo500" indicator-color="indigo500">
          <mu-tab class="doctor_title">医生介绍</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
        <div class="profile">
          <p>
            {{doctor.profile}}
          </p>
        </div>
        </div>
        <div v-if="doctor.skills">
        <mu-tabs inverse color="indigo500" indicator-color="indigo500">
          <mu-tab class="doctor_title">擅长</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
        <div class="profile">
          <p>
            {{doctor.skills}}
          </p>
        </div>
        </div>

        <mu-tabs inverse color="indigo500" indicator-color="indigo500">
          <mu-tab class="doctor_title">预约时间</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
        <!-- <div class="profile">
            <p>“……是的，我承认从很早以前我都害怕死亡，在医院里被人研究的时候，以前做出布局的时候，其实我很怕死的，因为除了死以外，我没有值得珍惜的东西，很奇怪是吧？恰好是这样我真的很怕死，因为我想要有值得珍惜的东西之后，死亡之后才会不寂寞，我才能够安然的面对死亡……”</p>
        </div> -->
        <mu-list v-for="item in doctor.order_date" :key="item.day">
          <mu-list-item avatar button :ripple="false">
            <!-- <mu-list-item-action>
                <mu-avatar>
                <img src="../../assets/images/avatar1.jpg">
                </mu-avatar>
            </mu-list-item-action> -->
            <mu-list-item-title
              >{{ item.day }} {{ item.week }}</mu-list-item-title
            >
            <mu-list-item-action>
              <mu-button @click="openSelectTime(item)" color="indigo500"
                >预约</mu-button
              >
            </mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </mu-list>
      </mu-container>

      <!-- <mu-row>
        
      </mu-row> -->
    </mu-container>
    <Footer  shift="home"/>
    <mu-dialog
      width="360"
      transition="slide-bottom"
      fullscreen
      scrollable
      lock-scroll
      :open.sync="selectTimeDialog"
    >
      <mu-appbar color="indigo500" title="选择预约时间">
        <mu-button slot="right" icon @click="selectTimeDialog = false">
          <mu-icon value="close"></mu-icon>
        </mu-button>
        <!-- <mu-button slot="right" flat  @click="closeFullscreenDialog">
          Done
        </mu-button> -->
      </mu-appbar>
      <div class="select_top">
        {{ selectDate.day }} {{ selectDate.week }} {{ doctorForm.time }}
      </div>
      <div style="padding: 10px 5px">
        <mu-tabs inverse color="indigo500" indicator-color="indigo500">
          <mu-tab>上午</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
        <div>
          <mu-button
            v-for="(item, index) in order_time.am"
            :key="index"
            id="time"
            :class="{ time_select: doctorForm.time == item.time }"
            @click="selectTime(item)"
            :disabled="item.is_can_order==0"
          >
            {{ item.time }}
          </mu-button>
        </div>
      </div>
      <div style="padding: 10px 5px">
        <mu-tabs inverse color="indigo500" indicator-color="indigo500">
          <mu-tab>下午</mu-tab>
        </mu-tabs>
        <mu-divider></mu-divider>
        <!-- <mu-flex class="flex-wrapper" justify-content="start">
          <mu-flex justify-content="center" v-for="item,index in order_time.pm" :key="index">
            <mu-button small  id="time" :class="{time_select: orderForm.time == item}" @click="selectTime(item)">
             {{item}}
           </mu-button>
          </mu-flex>
        </mu-flex> -->
        <div>
          <mu-button
            v-for="(item, index) in order_time.pm"
            :key="index"
            id="time"
            :class="{ time_select: doctorForm.time == item.time }"
            :disabled="item.is_can_order==0"
            @click="selectTime(item)"
          >
            {{ item.time }}
          </mu-button>
        </div>
      </div>
      <div class="submit">
        <mu-button color="indigo500" large @click="confirm()">下一步</mu-button>
      </div>
    </mu-dialog>
    <!-- 预约确认页面 -->
    <mu-dialog
      width="360"
      transition="slide-bottom"
      fullscreen
      scrollable
      lock-scroll
      :open.sync="orderDialog"
    ><mu-container>
      <mu-appbar color="indigo500" title="预约详情">
        <mu-button slot="right" icon @click="orderDialog = false">
          <mu-icon value="close"></mu-icon>
        </mu-button>
      </mu-appbar>
      <mu-list>
        <mu-list-item avatar :ripple="false" id="doctor">
          <mu-list-item-action>
            <mu-avatar>
              <img :src="doctor.avatar" />
            </mu-avatar>
          </mu-list-item-action>
          <mu-list-item-content>
            <mu-list-item-title id="doctor_name">{{
              doctor.name
            }}</mu-list-item-title>
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                >{{ doctorForm.office }} - {{ hospital.title }}</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊医院：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px">{{doctorForm.hospital}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊科室：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px">{{doctorForm.office}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊地点：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px">{{hospital.address}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >联系电话：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px"><a :href="'tel:'+hospital.telephone">{{hospital.telephone}}</a></span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊日期：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#000; margin: 5px 0px">{{doctorForm.date}}</span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button   @click="changeTime()">
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
                >就诊时间：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action><span style="color:#3f51b5; margin: 5px 0px">{{doctorForm.time}}<i class="mdui-icon material-icons">&#xe5cc;</i></span></mu-list-item-action>
        </mu-list-item>
        <mu-list-item button @click="openSelectPatientDialog()">
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
              
                >就诊人：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action >
            <span style="color:#3f51b5; margin: 5px 0px" v-if="doctorForm.patient" >{{doctorForm.patient.name}}/{{doctorForm.patient.mobile}}<i class="mdui-icon material-icons">&#xe5cc;</i></span>
            <span style="color:#3f51b5; margin: 5px 0px" v-else>请选择就诊人<i class="mdui-icon material-icons">&#xe5cc;</i></span>

          </mu-list-item-action>
        </mu-list-item>
        <mu-list-item >
          <mu-list-item-content>
            <mu-list-item-sub-title>
              <span style="color:#000; margin: 5px 0px"
              
                >备注：</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action >
            <mu-form :model="doctorForm">
            <mu-form-item prop="remark">
              <mu-text-field  v-model="doctorForm.remark"></mu-text-field>
            </mu-form-item>
            </mu-form>
          </mu-list-item-action>
        </mu-list-item>
        <!-- <mu-form>
        <mu-form-item prop="textarea" label="Textarea">
          <mu-text-field multi-line :rows="3" :rows-max="6" v-model="doctorForm.remark"></mu-text-field>
        </mu-form-item>
        </mu-form> -->
        <mu-divider></mu-divider>
        

      </mu-list>
      <div class="information">
        <i class="mdui-icon material-icons">&#xe000;</i>
        <span>预约即表示同意</span><span @click="noticeDialog=true" style="color:#3f51b5;font-weight:bold;">《预约须知》</span>
      </div>
      <mu-card style="width: 100%; max-width: 1000px; margin: 20px auto;">
        <mu-card-text class="notice">
          <span style="font-weight:bold">温馨提示</span><br/>
          <!-- ★请在预约时间之前携带身份证完成就诊卡实名认证。<br/>
          ★如需取消预约，请在就诊日前一日22点之前操作。<br/> -->
          ★预约时间点成功提交后，仅代表预约详细信息提交成功；<br/>
          ★具体预约时间点，我们会以最终工作人员和您确认的预约事项来调整；<br/>
          ★如您不清楚就诊需要的时间长短，可电话咨询；<br/>
          ★需加号预约，可致电门店，确认是否可以加号；
        </mu-card-text>
      </mu-card>
      <div class="submit">
        <mu-button color="indigo500" @click="order_submit()" large>确认预约</mu-button>
      </div>
      </mu-container>
    </mu-dialog>
    <mu-dialog
      width="360"
      transition="slide-bottom"
      fullscreen
      scrollable
      lock-scroll
      :open.sync="selectPatientDialog"
    ><mu-container>
      <mu-appbar color="indigo500" title="选择就诊人">
        <mu-button slot="right" icon @click="selectPatientDialog = false">
          <mu-icon value="close"></mu-icon>
        </mu-button>
      </mu-appbar>
    <SelectPatient @selectPatient="selectPatient"/>
    </mu-container>
    </mu-dialog>
    <mu-dialog width="360" :open.sync="noticeDialog">
    <span v-html="hospital.notice" class="notice_content"></span>
    <mu-button slot="actions" flat color="primary" @click="noticeDialog=false">关闭</mu-button>
  </mu-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";
import SelectPatient from "../../components/order/SelectPatient.vue"

export default {
  name: "Home",
  components: {
    Footer,
    SelectPatient,
  },
  data() {
    return {
      title:'',
      doctor: {},
      hospital:{},
      doctorForm: {
        jgbm: this.$route.query.jgbm,
        hospital:'',
        yyksbm:'',
        office:'',
        rybm: this.$route.query.rybm,
        date: "",
        time: "",
        patient:{},
        remark:'',
      },
      patients:[],
      order_dates: [],
      order_time: {},
      selectTimeDialog: false,
      selectDate: {},
      // orderForm: {
      //   date: "",
      //   time: "",
      // },
      timeForm: {},
      orderDialog: false,
      selectPatientDialog:false,
      notice:'',
      noticeDialog:false,
    };
  },
  created() {
    
    // this.getOffices();
    
    this.getDoctorDetail();
    this.getHospital_detail();
  },
  methods: {
    getDoctorDetail() {
      this.$http.post("doctor_detail", this.doctorForm).then((res) => {
        if(res.data.code == 204){
          this.$router.push({'name':'Login'})
          return false;
        }
        this.doctor = res.data.data;
        this.doctorForm.yyksbm = this.doctor.office.yyksbm
        this.doctorForm.office = this.doctor.office.title
        console.log(this.doctor.name)
        this.title = this.doctor.name+' - 可恩口腔医院';
        // this.$setTitle(this.doctor.name+' - 可恩口腔医院2');

        
      });
    },
    getHospital_detail(){
      this.$http.get('hospital/'+this.doctorForm.jgbm)
      .then((res)=>{
        if(res.data.code != 200){
          this.$alert(res.data.msg)
          return false;
        }
        this.doctorForm.hospital = res.data.data.title;
        this.hospital = res.data.data
        
      })
    },
    openSelectTime(item) {
      this.selectTimeDialog = true;
      this.doctorForm.date = item.date;
      // this.orderForm.date = item.date;
      this.selectDate = item;
      this.getOrderTime();
    },
    selectTime(item) {
      this.doctorForm.time = item.time;
      // console.log(this.orderForm);
    },
    confirm() {
      if (this.doctorForm.time == "") {
        this.$alert("请选择预约时间！");
        return false;
      }
      this.getPatient()
      this.selectTimeDialog = false;
      this.orderDialog = true;
    },
    getOrderTime() {
      this.$http.post("order_time", this.doctorForm).then((res) => {
        this.order_time = res.data.data;
      });
    },
    changeTime(){
      this.selectTimeDialog = true;
      this.orderDialog = false;
    },
    getPatient(){
      this.$http.get('patient',this.patientForm)
      .then((res)=>{
        if(res.data.code != 200){
          this.$alert(res.data.msg)
          return false;
        }
        this.patients = res.data.data;
        this.doctorForm.patient = res.data.data[0];
      })
    },
    openSelectPatientDialog(){
      // this.orderDialog = false;
      this.selectPatientDialog = true;      
    },
    selectPatient(item){
      this.selectPatientDialog = false;    
      // this.orderDialog = true;
      this.doctorForm.patient = item;
    },
    //提交预约
    order_submit(){
      if(!this.doctorForm.patient){
        this.$alert('请选择就诊人！')
        return false;
      }
      if(!this.doctorForm.time){
        this.$alert('请选择预约时间！')
        return false;
      }
      this.$http.post('order',this.doctorForm).then((res)=>{
        if(res.data.code!=200){
          this.$alert(res.data.msg);
          return false;
        }
        this.$alert(res.data.msg)
        .then((res)=>{
          this.$router.push({'name':'OrderList'})
        })
      })

    },
  },
  watch: {},
  mounted(){
    // console.log(this.doctor.name)
    // document.title = this.doctor.name+' - '+this.hospital.title;

  }
};
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
.content {
  margin-bottom: 85px;
}
.mu-card {
  margin: 12px auto;
}
/* .mu-item{
  margin:10px 10px;
  border:1px solid #ccc;
  border-radius: 10px;
  padding:10px;
  
} */
/* .mu-item{
  margin:2px auto;
} */
.mu-select {
  margin: 10px;
}
#doctor {
  margin: 30px 0px;
}
.mu-list li {
  margin: 0px 0px;
}
.mu-avatar {
  width: 100px !important;
  height: 100px !important;
}
.mu-item-action {
  min-width: 120px !important;
}
.mu-sub-header {
  text-align: left;
  font-weight: bold;
  font-size: 36px;
}
#doctor_name {
  font-weight: bold;
  font-size: 28px;
  height: 50px;
  line-height: 40px;
}
.mu-item-sub-title {
  line-height: 2;
  font-size: 14px;
  font-weight: 500;
}
.mu-item-sub-title .material-icons {
  font-size: 24px;
  height:40px;
}
.mu-select {
  width: 90% !important;
}
.order_date {
  font-weight: bold;
  font-size: 18px;
  line-height: 3em;
}
.date_active {
  color: white;
  background-color: #3f51b5;
  border-radius: 50%;
  max-width: 3em;
  /* padding:3px; */
  /* width:35px; */
}
.mu-tab-wrapper {
  font-weight: bold !important;
}
.profile p {
  margin: 20px 0;
  text-align: left;
  text-indent:2em;
}
#time {
  margin: 8px 5px;
}
.time_select {
  background-color: #3f51b5 !important;
  color: white !important;
}
.submit {
  margin: 30px auto;
  text-align: center;
}
/* .submit button{
  width:300px;
} */
.select_top {
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin: 10px auto;
  font-size: 20px;
}
.information span{
  color:#000;
  margin-top:10px;
}
.information .material-icons{
  
  font-size:24px;
  margin-top:10px;
}
.notice{
  background-color: #eee;
  line-height:28px;
}
.mu-form-item .mu-input{
  height:48px;
  padding-top:15px;
}
/* .mu-dialog-fullscreen .mu-dialog-body {
    padding: 0;
    max-height: 1200px !important;
} */
.notice_content{
  font-size:12px;
}
</style>
<style>
.mu-dialog-fullscreen .mu-dialog-body {
    padding: 0;
    max-height: 1200px !important;
}
.mu-dialog{
  overflow: scroll;
  -webkit-overflow-scrolling:touch;
}
.doctor_title {
  /* color:#000  !important; */
  font-weight: bold !important;
}
</style>