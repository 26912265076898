<template>
  <div>
    <mu-card
      style="width: 100%; margin: 20px auto"
      v-for="item in patients"
      :key="item.mobile"
    >
      <mu-list class="patient">
        <mu-list-item button>
          <mu-list-item-content>
            <mu-list-item-title @click="openEditDialog(item.id)"
              >{{ item.name }}
              <span color="red500"
                ><i class="mdui-icon material-icons">&#xe895;</i></span
              ></mu-list-item-title
            >
            <mu-list-item-sub-title>
              <span style="color: rgba(0, 0, 0, 0.87); margin: 5px 0px"
                >手机号码：{{ item.mobile }}<br />身份证号：{{
                  item.id_card
                }}</span
              >
            </mu-list-item-sub-title>
          </mu-list-item-content>
          <mu-list-item-action>
            <!-- <div  v-if="item.is_default==1">默认就诊人</div> -->
            <mu-button color="indigo500" @click="selectPatient(item)"
              >选择就诊人</mu-button
            >
          </mu-list-item-action>
        </mu-list-item>

        <!-- <mu-divider></mu-divider> -->
      </mu-list>
    </mu-card>
    <div class="submit">
      <mu-button color="indigo500" @click="addDialog = true" large
        >添加就诊人</mu-button
      >
    </div>

    <mu-dialog
      width="360"
      transition="slide-bottom"
      fullscreen
      :open.sync="addDialog"
    >
      <mu-appbar color="indigo500" title="添加就诊人">
        <mu-button slot="right" icon @click="addDialog = false">
          <mu-icon value="close"></mu-icon>
        </mu-button>
      </mu-appbar>
      <div style="padding: 24px">
        <mu-form
          ref="form"
          :model="patientForm"
          label-position="top"
          label-width="80"
          class="mu-demo-form"
        >
          <mu-form-item
            label="姓名"
            help-text="请输入真实姓名"
            prop="name"
            :rules="nameRules"
            :max-length="10"
          >
            <mu-text-field
              v-model="patientForm.name"
              prop="name"
            ></mu-text-field>
          </mu-form-item>
          <mu-form-item label="手机号码" prop="mobile" :rules="mobileRules">
            <mu-text-field
              v-model="patientForm.mobile"
              prop="mobile"
              :max-length="11"
            >
            </mu-text-field>
          </mu-form-item>
          <mu-form-item
            label="验证码"
            prop="code"
            :rules="codeRules"
            :max-length="6" 
          >
            <mu-text-field v-model="patientForm.code" prop="code" :max-length="6">
              <mu-button color="indigo500" :disabled="send_code_show==false" small @click="send_code()">发送验证码</mu-button>
            </mu-text-field>
            
          </mu-form-item>
          <mu-form-item label="身份证号码" prop="idcard" :max-length="18">
            <mu-text-field
              v-model="patientForm.idcard"
              prop="mobile"
            ></mu-text-field>
          </mu-form-item>
          <mu-form-item prop="is_default">
            <mu-checkbox
              label="设为默认就诊人"
              v-model="patientForm.is_default"
            ></mu-checkbox>
          </mu-form-item>
          <mu-form-item>
            <mu-button color="indigo500" @click="addPatient()" large
              >添加就诊人</mu-button
            >
          </mu-form-item>
        </mu-form>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      user: {},
      addDialog: false,
      editDialog: false,
      send_code_show:false,
      patients: [],
      patient: {},
      patientForm: {
        name: "",
        mobile: "",
        id_card: "",
        is_default: true,
      },
      nameRules: [
        { validate: (val) => !!val, message: '必须填写姓名'},
        { validate: (val) => val.length >= 2 && val.length <= 10, message: "姓名长度必须大于2，小于10" },
      ],
      mobileRules: [
        { validate: (val) => !!val, message: '必须填写手机号码'},
        { validate: (val) => val.length ==11 , message: '请输入正确的手机号码'}
      ],
      codeRules: [
        { validate: (val) => !!val, message: "请填写验证码" },
        {
          validate: (val) => val.length == 6,
          message: "请输入正确的验证码",
        },
      ],
    };
  },
  created() {
    document.title = "就诊人管理";
    this.getPatient();
  },
  methods: {
    getPatient() {
      this.$http.get("patient", this.patientForm).then((res) => {
        if (res.data.code != 200) {
          this.$alert(res.data.msg);
        }
        this.patients = res.data.data;
      });
    },
    addPatient() {
      // console.log(this.patientForm)
      // return false;
      this.$refs.form.validate().then((result) => {
        this.$http.post("patient", this.patientForm).then((res) => {
          if (res.data.code != 200) {
            this.$alert(res.data.msg);
          }
          this.$alert(res.data.msg).then((res) => {
            this.addDialog = false;
            this.patientForm.name = "";
            this.patientForm.mobile = "";
            this.patientForm.id_card = "";
            this.patientForm.is_default = false;
            this.getPatient();
          });
        });
      });
    },
    //设为默认就诊人
    setDefault(id) {
      this.$http.post("set_default", { patient_id: id }).then((res) => {
        if (res.data.code != 200) {
          this.$alert(res.data.msg);
          return false;
        }
        this.$alert(res.data.msg).then((res) => {
          this.getPatient();
        });
      });
    },
    openEditDialog(id) {
      this.editDialog = true;
      this.$http.get("patient/" + id).then((res) => {
        if (res.data.code != 200) {
          this.$alert(res.data.msg);
          return false;
        }
        this.patientForm = res.data.data;
        // if(res.data.data.is_default == 1){
        //   this.patientForm.is_default = true;
        // }else{
        //   this.patientForm.is_default = false;
        // }
      });
    },
    editPatient(id) {
      this.$refs.form.validate().then((result) => {
        this.$http.patch("patient/" + id, this.patientForm).then((res) => {
          if (res.data.code != 200) {
            this.$alert(res.data.msg);
          }
          this.$alert(res.data.msg).then((res) => {
            this.editDialog = false;
            this.patientForm.name = "";
            this.patientForm.mobile = "";
            this.patientForm.id_card = "";
            this.patientForm.is_default = false;
            this.getPatient();
          });
        });
      });
    },
    delPatient(id) {
      this.$confirm("确认要删除 ?", "提示").then((res) => {
        if (res.result) {
          this.$http.post("del_patient/", { id: id }).then((res) => {
            this.$alert(res.data.msg).then((res) => {
              this.editDialog = false;
              this.getPatient();
            });
          });
        }
      });
    },
    selectPatient(item){
        this.$emit('selectPatient',item);
    },
    send_code(){
      if(this.patientForm.mobile.length !=11 ){
        this.$alert('请输入正确的手机号码！');
      }
      this.$http.post('send_code',this.patientForm)
      .then((res)=>{
        if(res.data.code == 200){
          this.$alert(res.data.msg);
        }
      })

    },
  },
  watch: {
    'patientForm.mobile':function(newValue,oldValue){
      if(newValue.length == 11){
        this.send_code_show = true;
      }else{
        this.send_code_show = false;
      }
    }
  },
};
</script>
<style scoped>
.patient {
  padding: 30px 0px;
}
/* .mu-item {
  height: 84px !important;
} */
.patient .mu-item-title {
  line-height: 36px;
  height: 40px;
}
.patient .material-icons {
  line-height: 36px;
  font-size: 24px;
  color: #f44336 !important;
}
.patient .mu-item-sub-title {
  font-size: 14px;
  line-height: 28px;
}
.submit{
  margin:30px auto;
}
</style>
